import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { ProductCard } from "@/components/basic/Card";
import { TestimonialCarousel } from "@/components/TestimonialCarousel";
import { QualitiesList } from "@/components/QualitiesList";
import { TextSection } from "@/components/layout/TextSection";
import { Link } from "@/components/basic/Link";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import ReactMarkdown from "react-markdown";

const TravelIndustrySolutionsPage: FC<PageProps<GatsbyTypes.Query>> = ({
  data,
}) => {
  const content = data.contentfulClientsAndSolutionsPage;

  return (
    <MainLayout pageName="travel-industry-solutions">
      <Helmet>
        <body className="ltr travel-industry-solutions" id="intro" />
      </Helmet>
      <SEO
        title={content.metaTitle}
        description={content.metaDescription.metaDescription}
        keywords={content.metaKeywords}
      />
      <section
        className="l-wrapper color-bg-negative"
        style={{ marginTop: 50 }}
      >
        <div className="clearfix l-dbl-push-bottom d-flex-box d-flex-box-wrap">
          {content.productPagesLeads.map(
            ({ shortName, lead: { lead }, icon: { file }, active, url }) => (
              <ProductCard
                title={shortName}
                description={lead}
                iconUrl={file.url}
                to={`/${url}`}
                key={url}
                active={active}
              />
            )
          )}
        </div>
      </section>
      <section
        className="l-wrapper color-bg-negative"
        style={{ marginTop: 60 }}
      >
        <h3 className="text-title">{content.travelsModuleTitle}</h3>
        {content.travelsModuleDescription && (
          <div className="l-8-cols-desktop l-dbl-push-bottom l-island l-bleed clearfix l-push-top">
            <div className="l-full l-push-bottom text-section">
              <ReactMarkdown>
                {content.travelsModuleDescription.travelsModuleDescription}
              </ReactMarkdown>
            </div>
          </div>
        )}
        <div
          className="clearfix l-dbl-push-bottom d-flex-box d-flex-box-wrap"
          style={{ marginTop: 48 }}
        >
          {content.travelsModuleProducts.map(
            ({ shortName, lead: { lead }, icon: { file }, active, url }) => (
              <ProductCard
                title={shortName}
                description={lead}
                iconUrl={file.url}
                to={`/${url}`}
                key={url}
                active={active}
              />
            )
          )}
        </div>
      </section>

      <div className="testimonial-box l-dbl-push-bottom color-bg-light l-with-dbl-vertical-gutters-mobile">
        <div className="l-wrapper clearfix">
          <TestimonialCarousel />
        </div>
      </div>
      <QualitiesList markdown={content.qualitiesList.qualitiesList} />
      <TextSection>
        <ReactMarkdown>{content.about.about}</ReactMarkdown>
        <div className="align-center l-with-gutter-top-mobile">
          <div className="l-push-bottom">Want to know more?</div>
          <a className="button button-ghost-primary" href="/contact">
            Send us a message
          </a>
        </div>
      </TextSection>
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
        <Link
          classname="button button-primary button-request-demo"
          url="/demo-form"
          text="request a demo"
        />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default TravelIndustrySolutionsPage;
export const query = graphql`
  {
    contentfulClientsAndSolutionsPage {
      productPagesLeads {
        ... on ContentfulProfessionalServicesPage {
          id
          url
          lead {
            lead
          }
          active
          shortName
          icon {
            file {
              url
            }
          }
        }
        ... on ContentfulProductLandingPage {
          lead {
            lead
          }
          shortName
          active
          url
          icon {
            file {
              url
            }
          }
        }
      }
      travelsModuleTitle
      travelsModuleDescription {
        travelsModuleDescription
      }
      travelsModuleProducts {
        ... on ContentfulProductLandingPage {
          lead {
            lead
          }
          shortName
          active
          url
          icon {
            file {
              url
            }
          }
        }
        ... on ContentfulProductLandingShortcut {
          lead {
            lead
          }
          shortName
          active
          url
          icon {
            file {
              url
            }
          }
        }
      }
      qualitiesList {
        qualitiesList
      }
      about {
        about
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
    }
  }
`;
